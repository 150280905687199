<template>
    <div>
        <b-breadcrumb class="mb-2">
            <b-breadcrumb-item to="/recurring/services/">Services Récurents</b-breadcrumb-item>
            
            <b-breadcrumb-item active>Questionnaire pour les feedback</b-breadcrumb-item>
        </b-breadcrumb>

        <div v-if="isQuizLoading" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
            <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
        </div>
        <!-- Order assignment Modal  -->
        <div v-else>
            <b-card title="Questionnaire pour les feedback">
                <validation-observer #default="{}" ref="addNewQiz" tag="form">
                
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" v-for="(form, index) in forms" :key="index" >
                    <b-row>
                        <b-col md="12">
            
                            <h5>Question</h5>
                            <b-form-group label-for="textarea-default">
                                <validation-provider #default="{ errors }" name="question" rules="required">
                                    <b-form-textarea id="textarea-default" v-model="form.question"
                                        :state="errors.length > 0 ? false : null" placeholder="Entrez la question "
                                        rows="3" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <b-col cols="12" md="4" class="mt-2 mb-2">
                            <b-button variant="danger" :disabled="forms.length == 1" @click="removeItem(index)">
                                Supprimer
                            </b-button>
                         </b-col>       
                    </b-row>
            </b-form>
            <b-row>
            <!-- Reapet form -->
            <b-col cols="12" md="8" class="mb-2 d-flex align-items-center justify-content-start mb-2">
                            <b-button variant="primary" @click="repeateAgain">
                                Ajouter une autre question
                            </b-button>
                        </b-col>
            <!-- Form Actions -->
            <b-col cols="12" md="4" class="mb-2 d-flex align-items-center justify-content-end mb-2">
                    <div class="d-flex " @click="applyAddQuizAction()">
                    <b-button :disabled="isFeedbackLoading" variant="primary" class="mr-2">
                        <div v-if="isFeedbackLoading">
                            <span> Chargement ... </span>
                            <b-spinner small />
                        </div>
                        <span v-else>Soumettre</span>
                    </b-button>
                </div>
                </b-col>
            </b-row>
            </validation-observer>
        </b-card>
        </div>
    </div>
</template>
  
<script>
import {
    BRow,
    BCol,
    BTab,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BTableLite,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BSpinner,
    BFormSelect,
    BForm,
    BCard,
    BTabs,
    BFormGroup,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
    components: {
        BSpinner,
        BTab,
        BRow,
        BCol,
        BFormInput,
        BCard,
        BTableLite,
        BButton,
        BTable,
        BMedia,
        BTabs,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BPagination,
        BTooltip,
        BFormSelect,
        BForm,
        BFormGroup,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        vSelect,
        BBreadcrumb,
        BBreadcrumbItem,
    },

    data() {
        return {
            /* eslint-disable global-require */
            isQuizLoading: false,
            forms: [
                    {
                    question: ""
                    },
                ],

            payload:{
               questions: [],
               service_id:''
            },
            isFeedbackLoading: false,
        }
    },

    computed: {
        ...mapGetters("orders", [
            "getDirectOrdersHaveNotProposal",
            "getDirectOrdersWhoNeedRecommandation",
        ]),
        ...mapGetters(["isDirectOrdersWhoNeedRecommandationLoading"]),
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters("roles", ["getBusinessManagersList"]),

       
    },

   

    created() {
       
        this.loadQuiz()
        
    },

    mounted() { },

    methods: {
        ...mapActions("services", ["getServiceEmployeesAction", "getQuizAction", "addQuizAction"]),
        ...mapActions("packages", [
      "getDirectOrderAction",
      "getDirectPackageOrdersAction",
      "sendFeedbackAction",
      "makePropositionAction",
      "getRecommendedEmployeesAction",
      "getDirectOrderProposedEmployeesAction",
      "getRecommendationEmployeesAction",
      "deleteDirectOrderPropositionAction",
      "getSalaryDetailsAction",
    ]),
        ...mapActions("orders", [
            "assignAnOrderToBusinessManagerAction",
            "loadMoreDirectOrdersWhoNeedRecommandationAction",
            "getDirectOrdersAssignedToBusinessManagerAction",
            "makeEmployeeRecommandationAction",
            "modifyRecommandationAction",
            "getRecommendedEmployeesAction",
            "getDirectOrderProposedEmployeesAction",
            "modifyRecommandationAction",
        ]),
        ...mapActions("professionals", ["deleteEmployeeRecommendationAction"]),

        repeateAgain() {
      this.$refs.addNewQiz.validate().then((success) => {
        if (success) {
          this.forms.push({
            question: ""
          });
          this.cpt += 1
        } else {
          reject();
        }
      });
    },
    removeItem(index) {
      this.forms.splice(index, 1);
      this.cpt -= 1
    },

    applyAddQuizAction(){
        this.payload.questions = this.forms
        this.payload.service_id = this.$route.params.id
        this.isFeedbackLoading = true;
        this.addQuizAction(this.payload)
        .then(response => {
            this.isFeedbackLoading = false;
            console.log(response)
            this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          )
          this.$router.push("/recurring/services/")
        })
        .catch(error => {
            this.isFeedbackLoading = false;
            console.log(error)
            this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        })
    },

        loadQuiz() {
            /* recurring/services/serviceId/quiz */
            this.isQuizLoading = true
            this.getQuizAction(this.$route.query.service)
                .then(response => {

                    response.data.forEach((element) => {
                        this.quiz.push({
                            quiz: element.id,
                            question: element.question,
                            answer: ""
                        });
                    });
                    this.quiz.push({
                        quiz: -1,
                        question: "Autre",
                        answer: ""
                    })
                    this.isQuizLoading = false

                    console.log('QUIZ', this.quiz)

                })
                .catch(error => {
                    this.isQuizLoading = false
                    console.log(error)
                })
        },

    applySendFeedbackAction() {
      this.isFeedbackLoading = true;
      if (this.getUser.role.slug === "super-administrateur") {
        this.payload.type = "CA"
      }
      this.payload.feedback = this.quiz
      let storeFeedback = {
        id: this.$route.params.id,
        quizs: this.payload.feedback
      }
        if(JSON.parse(localStorage.getItem("commandeFeedback")) !== null){
            const tabStore = JSON.parse(localStorage.getItem("commandeFeedback"))
            tabStore.push(storeFeedback );
    
                localStorage.setItem('commandeFeedback', JSON.stringify(tabStore))
        }
        else{
            let storeFeed = [{
            id: this.$route.params.id,
            quizs: this.payload.feedback
            }]
            localStorage.setItem('commandeFeedback', JSON.stringify(storeFeed))
        }


      
      this.sendFeedbackAction({
        orderId: this.$route.params.id,
        payload: this.payload,
      })
        .then(async (response) => {
          this.isFeedbackLoading = false;
          
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: response.message,
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          )
          this.$router.push({ 
            path: '/preview/pdf', 
          query: {
          makeActionOnPage: true, 
          actionType: 'modifier', 
          isUpdate: false, 
          pdf: response.feedbac_url,
          orderId: response.data.id,
          packageId: response.data.package_id,
          service: response.data.recurring_service_id
        }, })
        })
        .catch((error) => {
          this.isFeedbackLoading = false;
          console.log(error)
        //   this.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: "Erreur",
        //         icon: "AlertTriangleIcon",
        //         text: error.response.data.message,
        //         variant: "danger",
        //       },
        //     },
        //     {
        //       position: "top-center",
        //     }
        //   );
        });
    },

 
    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
  